import request from '@/utils/request'

export function fetchPartners (query) {
  return request({
    url: '/api/mall/admin/partner/get/list',
    method: 'get',
    params: query
  })
}
// 添加/编辑制券方
export function setPartner (data) {
  return request({
    url: '/api/mall/admin/partner/edit',
    method: 'post',
    data
  })
}
// 删除制券方
export function detPartners (data) {
  return request({
    url: '/api/mall/admin/partner/delete',
    method: 'post',
    data
  })
}
