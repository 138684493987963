<template>
  <div class="">
    <el-button type="primary"
               class="mt-15"
               @click="setTerrace()">添加制券方</el-button>
    <table-page dataName="partners"
                ref="table"
                :request="fetchPartners">
      <el-table-column prop="name"
                       label="制券方名称">
      </el-table-column>
      <el-table-column prop="mch_id"
                       label="制券方商户号">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.is_open">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>已启用
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已禁用
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="setTerrace(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="detPartners(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetTerrace ref="form"
                @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchPartners, detPartners } from '@/api/terrace'
import SetTerrace from './components/SetTerrace'
export default {
  name: 'Partner',
  data () {
    return {
      fetchPartners
    }
  },
  components: {
    TablePage,
    SetTerrace
  },
  methods: {
    setTerrace (row) {
      this.$refs.form.showModel(row)
    },
    detPartners (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await detPartners({
          partner_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
