<template>
  <el-dialog title="制券方设置"
             :close-on-click-modal='false'
             width="40%"
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-row>
        <el-form-item label="制券方名称"
                      prop="name">
          <el-input v-model="form.name"
                    placeholder="请输入制券方名称"></el-input>
        </el-form-item>
        <el-form-item label="制券方微信商户号"
                      prop="mch_id">
          <el-input v-model="form.mch_id"
                    placeholder="请输入微信商户号"></el-input>
        </el-form-item>
        <el-form-item label="appid">
          <el-input v-model="form.app_id"
                    placeholder="请输入appid（可不填）"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch v-model="form.is_open">
          </el-switch>
        </el-form-item>
      </el-row>

    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm('form')"
                 v-if="!form.user_id">重置</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">{{form.id?'修改':'立即创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { setPartner } from '@/api/terrace'
export default {
  props: {
  },
  data () {
    return {
      form: {
        name: '',
        mch_id: '',
        app_id: '',
        is_open: true
      },
      rules: {
        name: [
          { required: true, message: '请输入制券方名称', trigger: 'blur' }
        ],
        mch_id: [
          { required: true, message: '制券方微信商户号', trigger: 'blur' }
        ]

      },
      dialogVisible: false
    }
  },
  components: {
  },
  methods: {
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.$refs.form.clearValidate()
        this.form = Object.assign({}, formData)
        this.form.partner_id = this.form.id
      } else {
        this.form = {
          name: '',
          mch_id: '',
          app_id: '',
          is_open: true
        }
        this.$nextTick(() => {
          this.$refs.form.resetFields()
        })
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setPartner(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: this.form.id ? '修改成功' : '新增成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
